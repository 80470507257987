import { useEffect } from "react";
import httpClient from "../HTTPService";

function WidthdrawList(){

    useEffect(() =>{
  gteWithdrawlist()
    },[])
    const gteWithdrawlist = async () => {
        try {
            const result = await httpClient.post("/admin/withdraw-list", {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log(result.data.data);
            if (result.data.status == true) {
                // toast.success(result.data.message)
            }
            if (result.data.status == false) {
                // toast.error(result.data.message)

            }

        }

        catch (error) {
            // toast.error(error.message)
        }
    }
    return(
        <>
        </>
    )
}
export default WidthdrawList