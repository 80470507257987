import { useEffect } from 'react';
import './Help.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import httpClient from '../../HTTPService';
const Replay = ()=>{
    const [msgData, setMsgData] = useState();
    const [adminAnswer, setAnswer] = useState();
    const navigate = useNavigate()
    useEffect(()=>{
       if(localStorage.getItem('rply')){
          const msgPrs = JSON.parse(localStorage.getItem('rply'))
          setMsgData(msgPrs)
       }
    },[])
    // Message Replay
     const sendRply = async ()=>{
        try{
            const result = await  httpClient.post("/admin/helps/update",{id:msgData.id, answer:adminAnswer},{ headers:{
                "Content-Type": "application/json",
                "Accept": "application/json",  
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }})
            if(result.data.status == true){
                toast.success(result.data.message)
                setTimeout(()=>{
                    navigate('/help')
                })
            }
            if(result.data.status == false){
                toast.error(result.data.message)
            }
            
        }
    
        catch(error){
            toast.error(error.message)
        }
     }
    return(
        <div className="replay">
                <ToastContainer />
           <div className='card'>
             <div className='row'>
                <div className='col-md-12'>
                    <div className='formGroup'>
                        <label>{msgData?.question}</label>
                        <textarea value={adminAnswer} placeholder='Please Type answer' onChange={(val)=>{setAnswer(val.target.value)}}></textarea>
                    </div>
                    <div className='btnFlx'>
                    <button type='button' className='addBtn'onClick={()=>{navigate('/help');localStorage.removeItem('rply')}}>Cancel</button>
                    <button type='button' className='addBtn' onClick={sendRply}>Repaly</button>
                    </div>
                </div>
             </div>
           </div>
        </div>
    )
}
export default Replay;