
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import httpClient from "../../HTTPService";
import { toast } from 'react-toastify';
import { useEffect } from "react";
const AddEditSubscription = () => {
    const navigate = useNavigate();
    const [subscriptionName, setSubscriptionName] = useState();
    const [timePeriod, setTimePeriod] = useState();
    const [subDescription, setSubDescription] = useState();
    const [subPrice, setSubPrice] = useState();
    const [subDiscount, setSubDiscount] = useState();
    const [subTag, setSubTab] = useState();
    const [subStatus, setSubStatus] = useState();
    const [subId, setSubId] = useState();
    const [editMode, seteditMode] = useState(false)
    const [filnaldata, setfinaldata] = useState([])
    const [planename, setplanname] = useState('')
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search); // Parse query string
    const id = queryParams.get('id');

    useEffect(() => {
        if (id != undefined) {
            // setloadinigstate(true)
            httpClient.post("/admin/subscriptions/view", {
                "id": id
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                console.log(res.data.data, 'kkj')
                setSubscriptionName(res.data.data.name)
                let _actual_data = res.data.data.subscriptions?.map((item) => {
                    let _data = {
                        name: item?.name,
                        type: item?.type,
                        description: item?.description,
                        active: item?.active,
                        discount: item?.discount,
                        mrp: item?.mrp
                    }
                    return _data
                })
                setfinaldata(_actual_data)
                // setimagePrvw(res?.data?.data?.image_path)
                // setcatImageIconPrv(res?.data?.data?.icon_path)
                // setCategotyName(res?.data?.data?.name)
                // setStatus(res?.data?.data?.active)
                // setloadinigstate(false)
                //  setDetailsData(res.data)
            })

        }

        // console.log(result ,'category details')
    }, [id])
    // Edit Category
    // useEffect(() => {
    //     if (localStorage.getItem('editSubData')) {
    //         const currentData = JSON.parse(localStorage.getItem('editSubData'));
    //         console.log(currentData);
    //         setSubscriptionName(currentData.name);
    //         setTimePeriod(currentData.type)
    //         setSubDescription(currentData.description)
    //         setSubPrice(currentData.price)
    //         setSubDiscount(currentData.discount)
    //         setSubTab(currentData.tag)
    //         setSubStatus(currentData.active)
    //         seteditMode(true)
    //         setSubId(currentData.id)
    //     }

    // }, [])


    function ondeleteItems(indexs) {
        let _modifiedarray = filnaldata?.filter((obj, index) => index !== indexs)
        setfinaldata(_modifiedarray)
    }
    const addednewItem = () => {
        console.log(
            {
                name: planename,
                type: timePeriod,
                description: subDescription,
                active: subStatus,
                discount: subDiscount,
                mrp: subPrice
            }, 'data view.....'
        )
        setfinaldata(prevOptionData => {
            if (prevOptionData.length > 0) {
                return [...prevOptionData, {
                    name: planename,
                    type: timePeriod,
                    description: subDescription,
                    active: subStatus,
                    discount: subDiscount,
                    mrp: subPrice
                }];
            } else {
                return [{
                    name: planename,
                    type: timePeriod,
                    description: subDescription,
                    active: subStatus,
                    discount: subDiscount,
                    mrp: subPrice
                }];
            }
        });
    }

    const addCatform = async () => {
        // event.preventDefault();

        if (id != undefined) {
            // const formData = new FormData();
            let _data = {
                id, id,
                name: subscriptionName,
                subscription_data: filnaldata

            }
            // formData.append('id', id);
            // formData.append('name', subscriptionName);
            // formData.append('subscription_data', filnaldata);
            // console.log(Object.fromEntries(formData))
            try {
                const result = await httpClient.post("/admin/subscriptions/update", _data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status) {
                    localStorage.removeItem('editSubData')
                    document.getElementById('addForm').reset();
                    toast.success(result.data.message);
                    setTimeout(() => {
                        navigate('/subscription')
                    }, 1000)

                }


                if (result.data.status == false) {
                    toast.error(result.data.message)
                }


            }
            catch (error) {
                toast.error(error.message)
            }
        }
        else {
            const formData = new FormData();
            formData.append('name', subscriptionName);
            formData.append('subscription_data', filnaldata);
            let data = {
                name: subscriptionName,
                subscription_data: filnaldata

            }

            console.log(Object.fromEntries(formData))
            try {
                const result = await httpClient.post("/admin/subscriptions/create", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }

                });

                document.getElementById('addForm').reset();
                toast.success(result.data.message)
                setTimeout(() => {
                    navigate('/subscription')
                }, 1000)
            }
            catch (error) {
                toast.error(error.message)

            }
        }
    }

    return (
        <div className="cataddedit">
            <div className="card">
                <form id="addForm">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Subscription  Name</label>
                                <input type="text"
                                    // id="subscriptionName"
                                    // name="subscriptionName"
                                    placeholder="Name" onChange={(val) => setSubscriptionName(val.target.value)} value={subscriptionName} required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label> Plan Name</label>
                                <input type="text" name="subTag "
                                    placeholder="Enter Plan Name" onChange={(val) => setplanname(val.target.value)} value={planename} required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Validity</label>
                                <select
                                    id=""
                                    value={timePeriod} onChange={(val) => setTimePeriod(val.target.value)} >
                                    <option>Select Time Period</option>
                                    <option value="1">1 Months</option>
                                    <option value="3">3 Months</option>
                                    <option value="6">6 Months</option>
                                    <option value="12">12 Months</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Select Status</label>
                                <select value={subStatus} onChange={(val) => setSubStatus(val.target.value)} >
                                    <option>Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>

                                </select>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Price</label>
                                <input type="text" name="subPrice"
                                    placeholder="Enter Price"
                                    onChange={(val) => setSubPrice(val.target.value)} value={subPrice} required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Discount (%)</label>
                                <input type="text" name="subDiscount"
                                    placeholder="Enter Discounted Price"
                                    onChange={(val) => setSubDiscount(val.target.value)} value={subDiscount} required />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>Discounted Price</label>
                                <input type="text"
                                    name="subDiscount" placeholder=""
                                    value={typeof subPrice - (subDiscount * subPrice / 100) !== 'NaN' ? subPrice - (subDiscount * subPrice / 100) : 0}
                                    disabled
                                    required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="formGroup">
                                <label>Description</label>
                                <textarea name="subDescription "
                                    placeholder="Enter Description"
                                    onChange={(val) => setSubDescription(val.target.value)} value={subDescription} required></textarea>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>&nbsp;</label>
                                <button type="button" onClick={() => addednewItem()} className="addBtn">Add</button>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                </form>

                {
                    filnaldata?.map((item, index) => {
                        return (
                            <div className="col-md-4">
                                {/* <div className="item-list"> */}
                                <div className="formGroup">
                                    <div className="item-list">
                                        <div>
                                            <label>{item.name}</label>
                                            <input type="text" value={item.name} disabled />
                                        </div>
                                        <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                            <div className="">
                                                <p className="icon">X</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>

                                </div>
                            </div>
                            // </div>
                        )
                    })
                }

                <div className="col-md-12">
                    <div className="btngrp">
                        <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/subscription'); localStorage.removeItem('editSubData') }}>Cancel</button>
                        <button type="button" className="themeBtn" onClick={() => addCatform()}>{id != undefined ? "Update" : "Submit"}</button>
                    </div>

                </div>

            </div>

        </div>
    )
}
export default AddEditSubscription;