import { Link } from 'react-router-dom';
import './ProfitAddProfile.css';
const ProfitAddProfile = () =>{
    return(
        <div className='profitaddProfile'>
    <div className="row">
      <div className="col-md-12">
        <div className="search-box">
          <div className="searchtct">
            <i className="fa fa-search" aria-hidden="true"></i>
            <input type="text" name="text" placeholder="Search..."/>
            <button type="button" className="addBtn">Add Profit</button>
          </div>
        
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>SL NO</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Add Service</th>
                <th>Pay Amount</th>
                <th>Date</th>
                <th>Up 1 Distribution</th>
                <th>Up 2 Distribution</th>
                <th>Net Profit</th>
   
              </tr>
            </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Abc</td>
                  <td>9512457841</td>
                  <td>Jhon</td>
                  <td>₹ 5422</td>
                  <td>6/03/2024</td>
                  <td>₹ 50.00</td>
                  <td>₹ 50.00</td>
                  <td>₹ 184.00</td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>Abc</td>
                  <td>9512457841</td>
                  <td>Jhon</td>
                  <td>₹ 5422</td>
                  <td>6/03/2024</td>
                  <td>₹ 50.00</td>
                  <td>₹ 50.00</td>
                  <td>₹ 184.00</td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>Abc</td>
                  <td>9512457841</td>
                  <td>Jhon</td>
                  <td>₹ 5422</td>
                  <td>6/03/2024</td>
                  <td>₹ 50.00</td>
                  <td>₹ 50.00</td>
                  <td>₹ 184.00</td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>Abc</td>
                  <td>9512457841</td>
                  <td>Jhon</td>
                  <td>₹ 5422</td>
                  <td>6/03/2024</td>
                  <td>₹ 50.00</td>
                  <td>₹ 50.00</td>
                  <td>₹ 184.00</td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>Abc</td>
                  <td>9512457841</td>
                  <td>Jhon</td>
                  <td>₹ 5422</td>
                  <td>6/03/2024</td>
                  <td>₹ 50.00</td>
                  <td>₹ 50.00</td>
                  <td>₹ 184.00</td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>Abc</td>
                  <td>9512457841</td>
                  <td>Jhon</td>
                  <td>₹ 5422</td>
                  <td>6/03/2024</td>
                  <td>₹ 50.00</td>
                  <td>₹ 50.00</td>
                  <td>₹ 184.00</td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>Abc</td>
                  <td>9512457841</td>
                  <td>Jhon</td>
                  <td>₹ 5422</td>
                  <td>6/03/2024</td>
                  <td>₹ 50.00</td>
                  <td>₹ 50.00</td>
                  <td>₹ 184.00</td>
                </tr>
              </tbody>
          </table>
        </div>
        <div className="tablepgntn">
          <p>Show <select>
            <option>1</option>
          </select> Entries</p>
          <ul>
            <li>
                <Link>
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </Link>
   
            </li>
            <li className="active">
            <Link>01</Link>

            </li>
            <li>
                <Link>02</Link>
            </li>
            <li>
                <Link>03</Link>
            </li>
            <li>
                <Link>04</Link>
            </li>
            <li>
                <Link><i className="fa fa-chevron-right" aria-hidden="true"></i></Link>

            </li>
          </ul>
        </div>
      </div>
     </div>
        </div>
    )
}
export default ProfitAddProfile;