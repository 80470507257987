import './Member.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import httpClient from '../../HTTPService';
const Member = () => {
    const [offsetValue, setoffsetValue] = useState(0);
    const [memberlist, setmemberlist] = useState([]);
    useEffect(() => {
        getMembers();
    }, []);
    const getMembers = async () => {
        try {
            const result = await httpClient.post('/admin/my-members', {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (result.status === 200) {
                console.log(result.data.data, 'data..')
                setmemberlist(result?.data?.data)
            }

        } catch (error) {
            //   toast.error(error.message)
        }
    }
    return (
        <div className="member">

            <div className='member-header'>
                <h4>Members List</h4>
            </div>

            <div>
                <div className='member-align'>
                    <div>
                    <h5>Direct Members</h5>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>
                </div>
                <div>
                    <h5>Depth-2 Members</h5>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Member;