import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { useState } from 'react';
const Menu = () => {
  const [indexHum, setIndexNum] = useState(0);
  const [indexNum, setIndexNumsub] = useState(0)
  const menu = [
    {
      label: "Dashboard",
      url: "dashboard",
      icon: "fa fa-tachometer"
    },
    {
      label: "Cms",
      url: "cms",
      icon: "fa fa-database"
    },
    {
      label: "User",
      url: "alluser",
      icon: "fa fa-user",
      subMenu: [
        {
          label: "Action all user",
          url: "alluser",
        }
      ]
    },
    {
      label: "Category",
      url: "category",
      icon: "fa fa-tachometer",
      subMenu: [
        {
          label: "Sub Category",
          url: "subcategory",
        }
      ]
    },
    {
      label: "Form",
      url: "formlist",
      icon: "fa fa-wrench",
      subMenu: [
        {
          label: "Form Listing",
          url: "formlist",
        },
        {
          label: "Form Management",
          url: "formmanagement",
        },
      ]
    },
    {
      label: "Subscription",
      url: "subscription",
      icon: "fa fa-ticket"
    },
    {
      label: "Option Management",
      url: "optionmanagement",
      icon: "fa fa-list-ul"
    },
    // {
    //   label: "Notification",
    //   url: "notification",
    //   icon: "fa fa-bell"
    // },
    {
      label: "Profit",
      url: "addprofile",
      icon: "fa fa-line-chart",
      subMenu: [
        {
          label: "Profit From Add Profile",
          url: "addprofile",
        },
        {
          label: "Profit Form Service User",
          url: "serviceuser",
        }
      ]
    },
    // {
    //   label: "Service",
    //   url: "serviceuser",
    //   icon: "fa fa-bookmark",
    //   subMenu: [
    //     {
    //       label: "Service User",
    //       url: "serviceuser",
    //     },
    //     {
    //       label: "Service Category",
    //       url: "servicecategory",
    //     },
    //     {
    //       label: "Service Provider",
    //       url: "serviceprovider",
    //     }

    //   ]
    // },
    {
      label: "Reports",
      url: "reports",
      icon: "fa fa-file-text"
    },
    {
      label: "Member",
      url: "member",
      icon: "fa fa-users"
    },
    {
      label: "Withdrawal",
      url: "withdrawallist",
      icon: "fa fa-google-wallet"
    },
    {
      label: "How Its Work",
      url: "howitswork",
      icon: "fa fa-question-circle"
    },
    {
      label: "Help",
      url: "help",
      icon: "fa fa-question-circle"
    },
    {
      label: "Settings",
      url: "settings",
      icon: "fa fa-cog"
    },

  ]
  return (
    <div className="nav">

      <Link to="/dashboard" className='logo'>
        <img src={logo} />
      </Link>
      <ul className='main-nav'>
        {
          menu.map((item, index) => {
            return <li onClick={() => setIndexNum(index)} key={index} className={indexHum == index ? "active" : ""}>
              <Link to={item.url} className='menu-main-item'>
              <div>
                <i className={item.icon} aria-hidden="true">
                </i>{item.label}
                </div>
                {
                  item?.subMenu != undefined ?
                    indexHum != index ?
                      <i className="fa fa-angle-right" aria-hidden="true"></i> : <i className="fa fa-angle-down" aria-hidden="true"></i>

                    : ''
                }
              </Link>
              <ul className='sub-menu'>
                {
                  item.subMenu?.map((submenu, index) => {
                    return <li
                      className={indexNum == index ? "submenu_item_active sub-menu-item " : ""}
                      onClick={() => setIndexNumsub(index)} key={index}

                    >
                      <Link to={submenu.url}><span>{submenu.label}</span></Link>
                    </li>
                  })
                }
              </ul>



            </li>
          })
        }

      </ul>


    </div>
  )
}
export default Menu;