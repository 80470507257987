import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from './Layout/Layout';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login/Login';
import Dashboard from './Layout/Dashboard/Dashboard';
import User from './Layout/User/User';
import ProfitAddProfile from './Layout/Profit/ProfitAddProfile/ProfitAddProfile';
import ProfitServiceUser from './Layout/Profit/ProfitServiceUser/ProfitServiceUser';
import AddProfile from './Layout/Profile/AddProfile/AddProfile';
// import { Provider } from 'react-redux';
// import {store} from './Redux/Store/index';
import Category from './Layout/Category/Category';
import CategoryDetails from './Layout/Category/CategoryDetails';
import AddEditCategory from './Layout/Category/AddEditCategory';
import SubCategory from './Layout/Category/SubCategory';
import SubCategoryDetails from './Layout/Category/SubCategoryDetails';
import AddEditSubCategory from './Layout/Category/SubCategoryAddEdit';
import FormManagement from './Layout/FormManagement/FormManagement';
import Subscription from './Layout/Subscription/Subscription';
import SubscriptionDetails from './Layout/Subscription/SubscriptionDetails';
import AddEditSubscription from './Layout/Subscription/AddEditSubscription';
import OptionManagement from './Layout/OptionManagement/OptionManagement';
import AddEditOption from './Layout/OptionManagement/AddEditOption';
import HowItsWork from './Layout/HowItsWork/HowItsWork';
import HowItsWorkDetails from './Layout/HowItsWork/HowItsWorkDetails';
import HowItsWorksAddEdit from './Layout/HowItsWork/HowItsWorksAddEdit';
import Help from './Layout/Help/Help';
import Replay from './Layout/Help/Replay';
import FormListing from './Layout/FormManagement/FormListing';
import Member from './Layout/Member/Member';
import Cms from './Layout/Cms/Cms';
import UserEdit from './Layout/User/UserEdit';
import ForgetPassword from './forget_password/forgetpassword';
import WidthdrawList from './widthdraw/index'
import Report from '../src/Layout/report'
const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "forget_password",
    element: <ForgetPassword/>,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "alluser",
            element: <User />,
          },
          {
            path: "addprofile",
            element: <ProfitAddProfile />,
          },
          {
            path: "serviceuser",
            element: <ProfitServiceUser />,
          },
          {
            path: "addprofiles",
            element: <AddProfile />,
          },
          {
            path: "category",
            element: <Category />,
          },
          {
            path: "categoryview/:id",
            element: <CategoryDetails />,
          },
          {
            path: "addCategory",
            element: <AddEditCategory />,
          },
          {
            path: "subcategory",
            element: <SubCategory />,
          },
          {
            path: "subcategoryview/:id",
            element: <SubCategoryDetails />,
          },
          {
            path: "addsubcategory",
            element: <AddEditSubCategory />,
          },
          {
            path: "formmanagement",
            element: <FormManagement />,
          },
          {
            path: "subscription",
            element: <Subscription />,
          },
          {
            path: "subscriptionview/:id",
            element: <SubscriptionDetails />,
          },
          {
            path: "addeditsubscription",
            element: <AddEditSubscription />,
          },
          {
            path: "optionmanagement",
            element: <OptionManagement />,
          },
          {
            path: "addeditoptions",
            element: <AddEditOption />,
          },
          {
            path: "help",
            element: <Help/>,
          },
          {
            path: "howitswork",
            element: <HowItsWork/>,
          },
          {
            path: "howitsworkdetails/:id",
            element: <HowItsWorkDetails/>,
          },
          {
            path: "howitsworkaddedit",
            element: <HowItsWorksAddEdit/>,
          },
          {
            path: "messagereplay",
            element: <Replay/>,
          },
          {
            path: "formlist",
            element: <FormListing/>,
          },
          {
            path: "member",
            element: <Member/>,
          },
          {
            path: "withdrawallist",
            element: <WidthdrawList/>,
          },
          {
            path: "cms",
            element: <Cms/>,
          },
          {
            path: "useredit/:id",
            element: <UserEdit/>,
          },
          {
            path: "reports",
            element: <Report/>,
          }
          
        ]
      }
    ]
  },

])
root.render(
    <RouterProvider router={router} />
);



// const AppRoutes = () => (
//   <Router>
//   <Routes>
//     <Route path="/" element={<Login />} />
//     <Route path="/app" element={<App />}>
//       <Route element={<Layout />}>
//       <Route index element={<Dashboard />} />
//         <Route path="dashboard" element={<Dashboard />} />
//         <Route path="alluser" element={<User />} />
//         <Route path="addprofile" element={<ProfitAddProfile />} />
//         <Route path="serviceuser" element={<ProfitServiceUser />} />
//         <Route path="addprofiles" element={<AddProfile />} />
//         <Route path="category" element={<Category />} />
//         <Route path="categoryview/:id" element={<CategoryDetails />} />
//         <Route  path="addCategory"  element={<AddEditCategory />} />
//         <Route path="subcategory" element={<SubCategory />} />
//         <Route path="subcategoryview/:id" element={<SubCategoryDetails />} />
//         <Route path="addsubcategory" element={<AddEditSubCategory />} />
//         <Route path="formmanagement" element={<FormManagement />} />
//         <Route path="subscription" element={<Subscription />} />
//         <Route path="subscriptionview/:id" element={<SubscriptionDetails />} />
//         <Route path="addeditsubscription" element={<AddEditSubscription />} />
//         <Route path="optionmanagement" element={<OptionManagement />} />
//         <Route path="addeditoptions" element={<AddEditOption />} />
//         <Route path="help" element={<Help />} />
//         <Route path="howitswork" element={<HowItsWork />} />
//         <Route path="howitsworkdetails/:id" element={<HowItsWorkDetails />} />
//         <Route path="howitsworkaddedit" element={<HowItsWorksAddEdit />} />
//         <Route path="messagereplay" element={<Replay />} />
//         <Route path="formlist" element={<FormListing />} />
//         <Route path="member" element={<Member />} />
//         <Route path="cms" element={<Cms />} />
//         <Route path="useredit/:id" element={<UserEdit />} />
//       </Route>
//     </Route>
//   </Routes>
//   </Router>
// );

// // Render your application
// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   // <Router>
//     <AppRoutes />
//   // {/* </Router> */}
// );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
