
import './App.css';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Outlet
} from "react-router-dom";
function App() {
  return (
    <div className="App">
 
    <Outlet />
   </div>
  );
}

export default App;
