import './Help.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-accessible-accordion/dist/fancy-example.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import httpClient from '../../HTTPService';
import Loding from '../../Component/Loding/Loding';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Help =()=>{
const navigate = useNavigate();
const [offsetValue, setoffsetValue] = useState(0);
let textInput = React.createRef();
const [getLimit, setLimit] = useState(10); 
const [getHelpLists, setGetHelpLists] = useState()
    useEffect(()=>{
        getHelpList();
    },[offsetValue,getLimit]);
    // search
      // search Subcription
  const searchtxt =()=>{
    getHelpList(textInput.current.value);
  }
    // replay
    const msgReplay =(data)=>{
        console.log("ok");
        const msg = JSON.stringify(data)
        localStorage.setItem('rply', msg)
        navigate('/messagereplay')
    }
    // get help list
 const  getHelpList = async (search_name) =>{
    try{
        const result = await  httpClient.post("/admin/helps-list",{search_name,offset:offsetValue,limit:getLimit},{ headers:{
            "Content-Type": "application/json",
            "Accept": "application/json",  
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }})
        if(result.data.status == true){
            setGetHelpLists(result.data.data)
            // toast.success(result.data.message +" "+"featch Successfully!")
        }
        if(result.data.status == false){
            // toast.error(result.data.message)
        }
        
    }

    catch(error){
        // toast.error(error.message)
    }
 }
return(
    <div className="help">
   <ToastContainer />
        {getHelpLists? <div><div className='helpBox'>
            <div class="search-box">
          <div class="searchtct">
       
            <i class="fa fa-search" aria-hidden="true"></i>
            <input type="text" name="text" placeholder="Search..." ref={textInput}/>
            <button type='button' className='searchBtn'  onClick={searchtxt}>Search</button>
          </div>
        
        </div>
          <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>SL NO</th>
                <th>User Name</th>
                <th>Phone Number</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
         
         {
            getHelpLists?.map((item, index)=>{
                return <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.date}</td>
                    <td><span className={item?.status == 1? "active" :"inactive"}>{item?.status == 1? "Susess":"Pending"}</span></td>
                    <td><Link onClick={()=>{msgReplay(item)}} className='active'>Replay</Link></td>
                </tr>
            })
         }
       </tbody>
       </table>
       </div>
    
        </div>
           <div class="tablepgntn">
           <p>Show <select value={getLimit} onChange={(val)=>{setLimit(val.target.value);setoffsetValue(0)}}>
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
            <option>50</option>
          </select> Entries</p>
           <ul>
             {/* <li>
                 <Link><i class="fa fa-chevron-left" aria-hidden="true"></i></Link>
               
             </li> */}
             <li >
       <Link onClick={()=>{setoffsetValue(0)}}>01</Link>
 
   </li>
   <li>
   <Link onClick={()=>{setoffsetValue(10)}}>02</Link>

   </li>
   <li>
   <Link onClick={()=>{setoffsetValue(20)}}>03</Link>
   
   </li>
   <li>
   <Link onClick={()=>{setoffsetValue(30)}}>04</Link>
   
   </li>
             {/* <li>
                 <Link><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>
     
             </li> */}
           </ul>
         </div>
        </div>
        :<Loding/>}
       
  
    </div>
    )
}
export default Help;