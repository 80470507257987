import group from '../../assets/group.png';
import onlinepayment from '../../assets/online-payment.png';
import email from '../../assets/email.png';
import payment from '../../assets/payment.png';
import employe from '../../assets/employe.png';
import banUser from '../../assets/ban-user.png';
import { useEffect } from 'react';
import httpClient from '../../HTTPService';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './Dashboard.css';
const Dashboard = () =>{
  const [userData, setuserData] = useState()

  // const userData = useSelector((state)=>{
  //   // console.log(state.user.userData);
  //    console.log(state.user?.userData?.token);

  // })
    
 useEffect(()=>{
  getDashboardData();
 },[])


   const  getDashboardData = async()=>{

    try {
      const result = await httpClient.post('/admin/dashboard',{}, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      console.log(result.data.data);
      
      if(result.data.status){
        // toast.success("Deta featch Successfully!")
        setuserData(result.data.data)
      }
    
    }catch(error){
      // toast.error(error.message)
    }



   
  }
    return(
    <div className="dashboard">
         <ToastContainer />
 <div className="row">
  <div className="col-md-4">
    <div className="card">
      <h2>Total Registered members</h2>
      <div className="cardBtm">
       <img src={group} />
        <span>{ userData? userData?.total_members :<i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> }</span>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card">
      <h2>Non Paid Member</h2>
      <small className="inactive">Inactive</small>
      <div className="cardBtm">
       <img src={onlinepayment} />
       <span>{userData? userData?.non_paid_members : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card">
      <h2>Paid Member</h2>
      <small className="sucess">Active</small>
      <div className="cardBtm">
      <img src={onlinepayment} />
      <span>{userData? userData?.paid_members : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card flxcard">
      <div>
        <h2>Customer Queries</h2>
        <div className="cardBtm">
        <img src={email} />
        <span>{userData? userData?.customer_query : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
        </div>
      </div>
      <div>
        <h2>Not Replay</h2>
        <div className="cardBtm">
          <span className="blocknumber">{userData? userData?.not_reply : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card flxcard">
      <div>
        <h2>Payment Request</h2>
        <div className="cardBtm">
         <img src={payment} />
       <span>{userData? userData?.payment_request : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>

        </div>
      </div>
      <div>
        <h2>Unpaid</h2>
        <div className="cardBtm">
          <span className="blocknumber">{userData? userData?.unpaid : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card flxcard">
      <div>
        <h2>Add Profile Request</h2>
        <div className="cardBtm">
         <img src={employe} />
         <span>{userData? userData?.profile_request : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
        </div>
      </div>
      <div>
        <h2>Unapprove</h2>
        <div className="cardBtm">
          <span className="blocknumber">{userData? userData?.unapproved : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card">
      <h2>Weekly Report</h2>
      <div className="cardBtm">
        <ul>
          <li><span>1</span></li>
          <li><span>2</span></li>
          <li><span>3</span></li>
          <li><span>4</span></li>
        </ul>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card">
      <h2>Blocked Member</h2>
      <div className="cardBtm">
       <img src={banUser} />
        <span>{userData? userData?.block_members : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="card">
      <h2>Total Payment</h2>
      
      <div className="cardBtm">
       <img src={payment} />
        <span>{userData? userData?.total_payment : <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>}</span>
      </div>
    </div>
  </div>
 </div>
    </div>
    )
}
export default Dashboard;