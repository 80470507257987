import { useState } from "react";
import React, { useEffect } from 'react';
import httpClient from "../../HTTPService";
import './OptionManagement.css';
import Loding from "../../Component/Loding/Loding";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const OptionManagement = () => {
  const navigate = useNavigate()
  let textInput = React.createRef();
  const [getOptionList, setOptionList] = useState();
  const [deleteOptions, setDeleteOption] = useState(null)
  useEffect(() => {
    gtOptionList();
  }, []);
  // Edit Option List 
  const editItem = (e) => {
    console.log(e);
    const editOption = e
    // const editOption = JSON.stringify(e)
    navigate('/addeditoptions', {
      state: {
        message: ''
      }
    });
    const neweditOption = editOption?.options?.map((ele) => {
      const { option_name, ...rest } = ele; // Destructure to remove option_name
      return { ...rest, name: ele.option_name };
    })
    const newone = { id: editOption.id, name: editOption.name, active: editOption.active, options: neweditOption }

    localStorage.setItem('editOptions', JSON.stringify(newone))
  }
  // Delete Option List
  const openModal = (id) => {
    document.getElementById('modalpop').classList.add('open');
    setDeleteOption(id)
  }
  const deletconfirm = async () => {
    try {
      await httpClient.post('/admin/option-masters/delete', { id: deleteOptions }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      toast.success('Option Detele Successfully!')
      gtOptionList();
      closeBtn();

    }
    catch (error) {
      toast.error(error.message)
    }
  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }
  // Search Option List
  const searchtxt = () => {
    gtOptionList(textInput.current.value);
  }
  // Get Option List

  const gtOptionList = async (search_name) => {
    try {
      const result = await httpClient.post("/admin/option-masters-list", { search_name }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      setOptionList(result.data.data)
      console.log(result.data);
    }
    catch (error) {

    }
  }
  return (
    <div className="OptionManagement">
      <ToastContainer />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div class="search-box">
              <div class="searchtct">

                <i class="fa fa-search" aria-hidden="true"></i>
                <input type="text" name="text" placeholder="Search..." ref={textInput} />
                <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>
                <button type='button' className='searchBtn'><Link to="/addeditoptions" >Add</Link></button>

              </div>

            </div>
          </div>
        </div>
        {getOptionList ?
          getOptionList?.map((item, i) => {
            return <div className="col-md-3" key={i}>
              <div className="card">
                <div className="optionAction">
                  <Link className="editActn" onClick={() => { editItem(item) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                  <Link className="deleteActn" onClick={() => { openModal(item.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>
                </div>
                <h2>{item.name}</h2>
                <ul className="optionList">
                  {item?.options.map((optionItem, index) => {
                    return <li key={index}>{optionItem.option_name}</li>
                  })

                  }
                </ul>

              </div>
            </div>
          }) : <Loding />

        }

      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={deletconfirm}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OptionManagement;