import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import React, { useRef } from 'react';
import Loding from "../../Component/Loding/Loding";
import { useNavigate } from "react-router-dom";
const FormManagement = () => {
    const [chooseFldType, setchooseFldType] = useState()
    const [choosetype, setchoosetype] = useState()
    const [labelvalue, setLabel] = useState()
    const navigate = useNavigate()
    const [chooseName, setchooseName] = useState()
    const [optionValue, setOptions] = useState()
    const [chooseplaceholder, setChooseplaceholder] = useState()
    const [chooseRequired, setChooseRequired] = useState(0)
    const [getCategory, setgetCategory] = useState();
    const [getSubCategory, setgetSubCategory] = useState(null)
    const [selectSub, setectSub] = useState();
    const [subCat, setSubcatory] = useState();
    const [dynamicFld, setdynamicFld] = useState([]);
    const [optionList, setoptionList] = useState([]);
    const [lebelnmaedata, setleblenamedata] = useState({})
    const [loadingState, setLoadingState] = useState(false)
    const labeltype_data = [
        {
            id: 1,
            name: 'First Name',
            icon: 'user',
            icon_type: 'Feather'

        },
        {
            id: 2,
            name: "Last Name",
            icon: 'user',
            icon_type: 'Feather'

        },
        {
            id: 3,
            name: "Full Name",
            icon: 'user',
            icon_type: 'Feather'

        },
        {
            id: 4,
            name: "Username",
            icon: 'user',
            icon_type: 'Feather'

        },
        {
            id: 5,
            name: "Email Address",
            icon: 'mail',
            icon_type: 'Feather'

        },
        {
            id: 6,
            name: "Phone",
            icon: 'phone',
            icon_type: 'Feather'

        },

        {
            id: 7,
            name: "Date of Birth",
            icon: 'calender',
            icon_type: 'Feather'

        },

        {
            id: 9,
            name: "Address",
            icon: 'home',
            icon_type: 'Feather'

        },
        {
            id: 10,
            name: "Apartment/Suite/Unit",
            icon: 'home',
            icon_type: 'Feather'

        },
        {
            id: 11,
            name: "City",
            icon: 'city',
            icon_type: 'FontAwesome5'

        },

        {
            id: 12,
            name: "PIN",
            icon: 'location-pin',
            icon_type: 'Entypo'

        },

        {
            id: 13,
            name: "Company Name",
            icon: 'building-o',
            icon_type: 'FontAwesome'

        },
        {
            id: 14,
            name: "Job Title",
            icon: 'contacts',
            icon_type: 'AntDesign'

        },
        {
            id: 15,
            name: "Department",
            icon: 'building-o',
            icon_type: 'FontAwesome'

        },
        {
            id: 16,
            name: "Comments",
            icon: 'message1',
            icon_type: 'AntDesign'

        },
        {
            id: 17,
            name: "Suggestions",
            icon: 'settings-suggest',
            icon_type: 'MaterialIcons'

        },
        {
            id: 18,
            name: "Feedback",
            icon: 'feed-discussion',
            icon_type: 'Octicons'

        },

        {
            id: 20,
            name: "Review",
            icon: 'staro',
            icon_type: 'AntDesign'

        },
        {
            id: 21,
            name: "School/University Name",
            icon: 'school',
            icon_type: 'FontAwesome5'

        },
        {
            id: 22,
            name: "Degree",
            icon: 'school',
            icon_type: 'FontAwesome5'

        },
        {
            id: 23,
            name: "Major",
            icon: 'school',
            icon_type: 'FontAwesome5'

        },
        {
            id: 24,
            name: "Graduation Year",
            icon: 'calendar',
            icon_type: 'AntDesign'

        },
        {
            id: 25,
            name: "Employer Name",
            icon: 'user',
            icon_type: 'Feather'

        },
        {
            id: 26,
            name: "Job Title",
            icon: 'user',
            icon_type: 'Feather'
        },
        {
            id: 15,
            name: "Employment Start Date",
            icon: 'calendar',
            icon_type: 'AntDesign'

        },
        {
            id: 27,
            name: "Employment End Date",
            icon: 'calendar',
            icon_type: 'AntDesign'

        },
        {
            id: 28,
            name: "Query",
            icon: 'calendar',
            icon_type: 'AntDesign'

        },
        {
            id: 29,
            name: "Description",
            icon: 'calendar',
            icon_type: 'AntDesign'

        },
        {
            id: 30,
            name: "Notes",
            icon: 'calendar',
            icon_type: 'AntDesign'

        },
        {
            id: 31,
            name: "Additional Information",
            icon: 'calendar',
            icon_type: 'AntDesign'

        },



    ]
    const [isrequired, setisrequired] = useState(false)
    useEffect(() => {
        getcatname();

    }, []);
    useEffect(() => {
        if (getSubCategory) {
            console.log("ok");
            getSub(getSubCategory);
        }
    }, [getSubCategory])
    // get sub cat
    useEffect(() => {
        const _final_data = {
            name: chooseFldType,
            icon: 'home',
            icon_type: 'Feather'
        }
        setleblenamedata(_final_data)
        // console.log(chooseFldType)
    }, [chooseFldType])
    useEffect(() => {
        if (labelvalue !== null) {
            let fil_data = labeltype_data?.find(item => item.id == labelvalue)
            console.log(fil_data, 'seke')
            setleblenamedata(fil_data)
        }

    }, [labelvalue])
    useEffect(() => {
        if (choosetype !== null) {
            let choose_data = optionList?.find(item => item?.id == choosetype)
            const _final_data = {
                name: choose_data?.name,
                icon: 'home',
                icon_type: 'Feather'
            }
            console.log(_final_data)

            setleblenamedata(_final_data)
        }

    }, [choosetype])

    function ondeleteItems(indexs) {
        let _modifiedarray = dynamicFld?.filter((obj, index) => index !== indexs)
        setdynamicFld(_modifiedarray)
    }
    const getSub = async (category_id) => {
        // setLoadingState(true)
        try {
            const result = await httpClient.post("/admin/all-sub-categories-list", { category_id }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setectSub(result.data.data)
            // setLoadingState(false)

        }

        catch (error) {
            toast.error(error.message)
        }
    }
    // add From 
    const addFld = async (event) => {
        console.log(
            {
                type: chooseFldType,
                select_box_type: choosetype,
                label: lebelnmaedata?.name,
                name: lebelnmaedata?.name,
                place_holder: chooseplaceholder,
                is_required: chooseRequired,
                option: choosetype,
                icon: lebelnmaedata?.icon,
                icon_type: lebelnmaedata?.icon_type

            },
            'data...'
        )
        if (labelvalue === null || chooseFldType === null || lebelnmaedata?.name === null) {
            setisrequired(true)
            return
        }
        event.preventDefault();

        setdynamicFld(prevOptionData => {
            if (prevOptionData.length > 0) {
                return [...prevOptionData, {
                    type: chooseFldType,
                    select_box_type: choosetype,
                    label: lebelnmaedata?.name?.charAt(0).toUpperCase() + lebelnmaedata?.name?.slice(1),
                    name: lebelnmaedata?.name?.charAt(0).toUpperCase() + lebelnmaedata?.name?.slice(1),
                    place_holder: chooseplaceholder?.charAt(0).toUpperCase() + chooseplaceholder?.slice(1),
                    is_required: chooseRequired,
                    option: choosetype,
                    value: "",
                    icon: lebelnmaedata?.icon,
                    icon_value: lebelnmaedata?.icon_type
                }];
            } else {
                return [{
                    type: chooseFldType,
                    select_box_type: choosetype,
                    label: lebelnmaedata?.name?.charAt(0).toUpperCase() + lebelnmaedata?.name?.slice(1),
                    name: lebelnmaedata?.name?.charAt(0).toUpperCase() + lebelnmaedata?.name?.slice(1),
                    place_holder: chooseplaceholder?.charAt(0).toUpperCase() + chooseplaceholder?.slice(1),
                    is_required: chooseRequired, option: choosetype, value: "",
                    icon: lebelnmaedata?.icon,
                    icon_value: lebelnmaedata?.icon_type
                }];
            }
        });
        setchooseFldType('')
        setchoosetype()
        setleblenamedata({})
        setChooseplaceholder('')
        setChooseRequired('')
        // setLabel('')
        // setchooseName('')
        // setChooseplaceholder('')
        // setChooseRequired('')
        // setchoosetype('')
        document.getElementById('resetForm').reset()
        // setoptionList(false)
        // console.log(dynamicFld);

    }
    const FrmCreation = async () => {
        console.log(dynamicFld)

        // console.log({ category_id: getSubCategory, sub_category_id: subCat, form_data: dynamicFld }, 'dynam')
        try {
            const result = await httpClient.post("/admin/form/create", { category_id: getSubCategory, sub_category_id: subCat, form_data: dynamicFld }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            console.log(result.data.data);

            if (result.data.status == true) {
                toast.success("Form Creation Successfully!")
                navigate('/formlist')
            }
            if (result.data.status == false) {
                toast.error(result.data.message)
            }
        }

        catch (error) {
            toast.error(error.message)
        }

    }
    const setfldType = async (val) => {
        // setLoadingState(true)
        setchooseFldType(val)
        if (val == "select") {
            try {
                const result = await httpClient.post("/admin/option-masters-list", {}, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })

                setoptionList(result.data.data)
                // setLoadingState(false)

            }
            catch (error) {

            }
        } else {
            setoptionList([])
        }
    }
    const back = () => {

    }


    const getcatname = async () => {
        try {
            const result = await httpClient.post("/admin/all-categories-list", {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            setgetCategory(result.data.data);

        }
        catch (error) {

        }
    }
    return (
        <div className="formManagement">
            <ToastContainer />
            <div className="card">
                {
                    loadingState ? <Loding /> : null
                }

                <div className="row">
                    <div className="col-md-4">
                        <div className="formGroup">
                            <label>Parent Category *</label>
                            <select value={getSubCategory} onChange={(val) => { setgetSubCategory(val.target.value) }}>
                                <option disabled>Select Category</option>
                                {
                                    getCategory?.map((item, i) => {
                                        return <option key={i} value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="formGroup">
                            <label>Select Sub Category *</label>
                            <select value={subCat} onChange={(val) => { setSubcatory(val.target.value) }}>
                                <option>Choose Sub Category</option>
                                {
                                    selectSub?.map((item, i) => {
                                        return <option key={i} value={item?.id}>{item?.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <form onSubmit={addFld} id="resetForm">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Form Creation </h2>
                        </div>

                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Select Input Type *</label>

                                <select onChange={(val) => { setfldType(val.target.value) }}

                                // className={(isrequired || chooseFldType == null) ? 'required' :'' }

                                >
                                    <option>Select </option>
                                    <option value="text">Text Box</option>
                                    <option value="select">Select Box</option>
                                    <option value="textarea">Textarea</option>
                                    <option value="date">Date</option>
                                    <option value="time">Time</option>
                                    <option value="file">File</option>
                                </select>
                            </div>
                        </div>
                        {optionList?.length > 0 ?
                            <div className="col-md-3">
                                <div className="formGroup">
                                    <label>Choose Type *</label>
                                    <select
                                        //   className='required'
                                        value={choosetype} onChange={(val) => { setchoosetype(val.target.value) }}>
                                        <option selected value={0}>Choose Select Box</option>
                                        {
                                            optionList?.map((item, i) => {
                                                return <option
                                                    // onSelect={(item) => console.log(item)}
                                                    value={item.id} key={i}>{item.name}</option>
                                            })
                                        }

                                    </select>
                                </div>
                            </div> : ""
                        }
                        {
                            // chooseFldType !== 'date' && chooseFldType !== 'time' && chooseFldType !== 'fiile' ?

                            <div className="col-md-3">
                                <div className="formGroup">
                                    <label>Choose Label *</label>
                                    {
                                        (chooseFldType === 'select' || chooseFldType === 'date' || chooseFldType === 'file' || chooseFldType === 'time') ?
                                            <div>
                                                <select
                                                    disabled={chooseFldType === 'select' ? true : false}
                                                    value={lebelnmaedata?.name}

                                                >
                                                    {/* <option value={0} key={0}>Label Name</option> */}

                                                    <option value={0} key={0}>{lebelnmaedata?.name}</option>
                                                </select>
                                            </div>
                                            :

                                            <select value={labelvalue}
                                                // disabled={choosetype !== null ?  true : false} 
                                                onChange={(val) => { setLabel(val.target.value) }}
                                            //   className={(isrequired && labelvalue === null) ? 'required' :'' }
                                            >

                                                {/* { */}
                                                {/* choosetype !== null ?
                                                <option value={lebelnmaedata?.name} key={1}>{ lebelnmaedata?.name}</option>
                                                :
                                                <div> */}

                                                <option selected value={0}>Choose Label</option>
                                                {
                                                    labeltype_data?.map((item, i) => {
                                                        return <option value={item.id} key={i}>{item.name}</option>
                                                    })
                                                }
                                                {/* </div> */}
                                                {/* } */}

                                            </select>
                                    }
                                </div>
                            </div>
                            // : null
                        }
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Field Name</label>
                                <input type="text" disabled={true} value={lebelnmaedata?.name} placeholder="Enter Name" />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Placeholder *</label>
                                <input type="text" value={chooseplaceholder} placeholder="Enter Placeholder Name" onChange={(val) => { setChooseplaceholder(val.target.value) }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="formGroup">
                                <label>Required *</label>
                                <select
                                    //   className='required'
                                    value={chooseRequired} onChange={(val) => { setChooseRequired(val.target.value) }}>
                                    {/* <option  value={0}>Select Required Type</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option> */}
                                    <option>Select Required Type</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="formGroup">
                                <label>&nbsp;</label>
                                <button type="submit" className="addBtn">Add</button>
                            </div>

                        </div>
                    </div>
                </form>
                <form>
                    <div className="row" id="addDynamicFrm">
                        {
                            dynamicFld?.map((item, index) => {
                                if (item.type === "text") {
                                    // console.log("ok")
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label className="name-width">{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} placeholder={item.place_holder} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                if (item.type == "select") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <select>
                                                    <option selected>{item.place_holder}</option>
                                                    <option></option>
                                                </select>
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                }
                                if (item.type == "textarea") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <textarea name={item.name} placeholder={item.place_holder}></textarea>
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                if (item.type == "date") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} placeholder={item.place_holder} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                if (item.type == "time") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} placeholder={item.place_holder} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                if (item.type == "file") {
                                    return <div className="col-md-4" key={index}>
                                        <div className="formGroup">
                                            <label>{item.label}</label>
                                            <div className="item-list">
                                                <input type={item.type} name={item.name} />
                                                <div className="cross-icon" onClick={() => ondeleteItems(index)}>
                                                    <div className="">
                                                        <p className="icon">X</p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                }

                            })

                        }
                    </div>

                </form>
                <div className="row">
                    <div className="col-md-12">
                        <div className="btngrp">
                            <button type="button" style={{ marginLeft: "0px" }} className="addBtn" onClick={FrmCreation}>Save</button>
                            <button type="button" className="addBtn cnclBtn" onClick={back}>Cancel</button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
export default FormManagement;

