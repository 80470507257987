import './AddProfile.css';
const AddProfile = () =>{
    return(
     <div className='addProfile'>
          <div class="row">
      <div class="col-md-12">
       <div class="card_form">
         <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Full Name</label>
              <input type="text" placeholder=""/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Mobile Number</label>
              <input type="text" placeholder=""/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Services</label>
               <select>
                <option>Select</option>
               </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Payout Amount</label>
              <input type="text" placeholder=""/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Date</label>
              <input type="date" placeholder=""/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Up 1 Distribution</label>
              <input type="text" placeholder=""/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Up 2 Distribution</label>
              <input type="text" placeholder=""/>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Net Profit</label>
              <input type="text" placeholder=""/>
            </div>
          </div>
          <div class="col-md-12">
            <div class="btn_group">
              <button type="button" class="add-btn">Add</button>
              <button type="button" class="cancl-btn">Cancel</button>
            </div>
            </div>
         </div>
       </div>
      </div>
     </div>
     </div>
    )
}
export default AddProfile;