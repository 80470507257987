
import httpClient from '../../HTTPService';
import './HowItsWork.css';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loding from '../../Component/Loding/Loding';
import { useNavigate } from 'react-router-dom';
import videoimg from "../../assets/watch-movie.png"
const HowItsWork = ()=>{
const [offsetValue, setoffsetValue] = useState(0);
const [getLimit, setLimit] = useState(10); 
let textInput = React.createRef();
const [deleteyes, setdeleteyes] = useState(null)
const [getWorks, setGetWorks] = useState();
const navigate = useNavigate();
    useEffect(()=>{
        getWork();
      },[offsetValue,getLimit])
      // Edit Subcription
      const editItem = (e)=>{
        console.log(e);
        const editItem = JSON.stringify(e)
         navigate('/howitsworkaddedit');
         localStorage.setItem('editWorkData',editItem)
      }
      // Delete Work
      const  deletconfirm = async ()=>{
        try{
        const result =  await httpClient.post('/admin/works/delete',{id:deleteyes},{
            headers:{
              "Content-Type": "application/json",
              "Accept": "application/json",
              'Authorization': `Bearer ${localStorage.getItem('token')}`  
            }
          });


          if(result.data.status == true){
            toast.success('Detele Successfully!')
            getWork();
          closeBtn();
           }
           if(result.data.status == false){
            toast.error(result.data.message)
            closeBtn();
           }
    
          
        }
        catch(error){
          toast.error(error.message)    
        }
    
    }
      // search Work
      const searchtxt =()=>{
        getWork(textInput.current.value);
      }
      // get Work
    
     const getWork= async (search_name)=>{
        try{
            const result = await httpClient.post("/admin/works-list",{search_name,offset:offsetValue,limit:getLimit},{
                headers:{
                    "Content-Type": "application/json",
                    "Accept": "application/json",  
                    'Authorization': `Bearer ${localStorage.getItem('token')}`    
                }
            })
            if(result.data.status && result.data.data.length !== 0){
                // toast.success("Data featch Successfully!")
                setGetWorks(result.data.data);
                }
                if(result.data.data.length === 0){
                 toast.error("No Data Found")
                 setGetWorks(result.data.data);
                }
         }catch(error){
          // toast.error(error.message)
         }
      
    
    }
    
    const deleteBtn = (id)=>{
        document.getElementById('modalpop').classList.add('open')
        setdeleteyes(id)
    }
    const closeBtn = ()=>{
        document.getElementById('modalpop').classList.remove('open')
    }
    return(
        <div className="howitsWorks">
              <ToastContainer />
 <div class="row">
      <div class="col-md-12">
        <div class="search-box">
          <div class="searchtct">
       
            <i class="fa fa-search" aria-hidden="true"></i>
            <input type="text" name="text" placeholder="Search..." ref={textInput}/>
            <button type='button' className='searchBtn'  onClick={searchtxt}>Search</button>
            <button type='button' className='searchBtn'><Link to="/howitsworkaddedit" >Add</Link></button>
            
          </div>
        
        </div>
        {getWorks? <div>
          <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>SL NO</th>
                <th>Name</th>
                <th>Video</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
         
                {getWorks?.map((item, i)=>{
                   return <tr key={i}>
                    <td>{i+1}</td>
                    <td>{item.title? item.title: "--"}</td>
                 
                    <td>{item.video_path? <img src={videoimg}/>: "--"}</td>
                   
              
                  <td>
                    <span className={item?.active == 1? "active" :"inactive"}>{item?.active == 1? "Active":"Inactive"}</span>
                  </td>
                  <td>
                    <div class="action">
                    <Link  to={`/howitsworkdetails/${item?.id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                      <Link onClick={()=>{editItem(item)}}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                      <Link onClick={()=>{deleteBtn(item?.id)}}><i class="fa fa-trash" aria-hidden="true"></i></Link>
                 
                    </div>
                  </td>
                   </tr>
                })
                }
            </tbody>
          </table>
        </div>
        <div class="tablepgntn">
        <p>Show <select value={getLimit} onChange={(val)=>{setLimit(val.target.value);setoffsetValue(0)}}>
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>40</option>
            <option>50</option>
          </select> Entries</p>
          <ul>
            {/* <li>
                <Link><i class="fa fa-chevron-left" aria-hidden="true"></i></Link>
              
            </li> */}
            <li >
       <Link onClick={()=>{setoffsetValue(0)}}>01</Link>
 
   </li>
   <li>
   <Link onClick={()=>{setoffsetValue(10)}}>02</Link>

   </li>
   <li>
   <Link onClick={()=>{setoffsetValue(20)}}>03</Link>
   
   </li>
   <li>
   <Link onClick={()=>{setoffsetValue(30)}}>04</Link>
   
   </li>
            {/* <li>
                <Link><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>
    
            </li> */}
          </ul>
        </div>
        </div>:
         <Loding/>
     
        }
       
      </div>
         </div>
     <div class="modalpop" id="modalpop">
  <div class="modalsbody">
   <h3>Do You Want to Delete ?</h3>
   <div class="popBtn">
    <button type="button" class="secussBtn" onClick={deletconfirm}>
      Yes
    </button>
    <button type="button" class="cancelBtn" onClick={closeBtn}>
      No
    </button>
   </div>
  </div>
 </div>
        </div>
    )

}
export default HowItsWork;