import './Login.css';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import httpClient from '../HTTPService';
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const Loginform = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when form is submitted
  
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('device_token', ''); 

    console.log('putdata sent============:', formData);

    try {
      const result = await httpClient.post('/admin/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      
      });
      
      console.log('Login response------------------:', result);
      
  
      if (result.data.status === true) {
        toast.success("Login Successfully!")
  
        localStorage.setItem('token',result.data.token)
        localStorage.setItem('userData',JSON.stringify(result.data.data))
         navigate('/dashboard'); 
      } else {
        setErrorMsg('Wrong Username or Password');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMsg('An error occurred during login. Please try again.');
    } finally {
      setLoading(false); 
    }
  }

  return (
    <div className="logwapper">
      <div className="logwapper-body">
        <div className="logwapper-left">
          <a href="login.html"><img src={logo} alt="logo" /></a>
        </div>
        <div className="logwapper-right">
          <form onSubmit={Loginform}>
            <h1>Welcome Back</h1>
            <div className="form-group">
              <i className="fa fa-user" aria-hidden="true"></i>
              <input 
                type="text"
                name="email"
                placeholder="Email Id"
                onChange={(val) => setEmail(val.target.value)}
                value={email}
                required
              />
            </div>
            <div className="form-group">
              <i className="fa fa-lock" aria-hidden="true"></i>
              <input 
                type="password"
                name="password"
                placeholder="Password"
                onChange={(val) => setPassword(val.target.value)}
                value={password}
                required
              />
            </div>
            <div className="flx">
              <div className="checkboxs">
                <input type="checkbox" id="css" />
                <label htmlFor="css">Remember Me</label>
              </div>
              <a onClick={() => navigate('/forget_password')}>Forgot Password?</a>
            </div>
            <button type="submit" className="themeBtn" disabled={loading}>
              {loading ? 'LOADING...' : 'LOGIN'}
            </button>
            {errorMsg && <p className='errormsg'>{errorMsg}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
