import React, { useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import Loding from '../../Component/Loding/Loding';
import httpClient from '../../HTTPService';
import { useState } from 'react';
import './Category.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const SubCategory = () => {
  const [offsetValue, setoffsetValue] = useState(0);
  const navigate = useNavigate()
  const [getLimit, setLimit] = useState(10);
  const [deleteyes, setdeleteyes] = useState(null)
  const [getcat, setgetCat] = useState()
  let textInput = React.createRef();
  useEffect(() => {
    getCat();
  }, [offsetValue, getLimit]);
  // edit Item 
  const editItem = (e) => {
    console.log(e);
    // const editdata = JSON.stringify(e)
    navigate(`/addsubcategory?id=${e.id}`);
    // localStorage.setItem('editSubData', editdata)
  }
  // Search Category
  const searchtxt = () => {
    getCat(textInput.current.value);
  }
  // Delete Category

  const deleteBtn = (id) => {
    document.getElementById('modalpop').classList.add('open')
    setdeleteyes(id)

  }
  const deletconfirm = async () => {
    try {
      const result = await httpClient.post('/admin/sub-categories/delete', { id: deleteyes }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.status == true) {
        toast.success('Sub Category Detele Successfully!')
        getCat();
        closeBtn();
      }
      if (result.data.status == false) {
        toast.error(result.data.message)
        closeBtn();
      }


    }
    catch (error) {
      toast.error(error.message)
    }

  }
  // get Sub Category
  const getCat = async (search_name) => {
    try {
      const result = await httpClient.post('/admin/sub-categories-list', { search_name, offset: offsetValue, limit: getLimit }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.status && result.data.data.length !== 0) {
        // toast.success("Sub Category featch Successfully!")
        setgetCat(result.data.data);
        console.log(result.data.data);
      }
      if (result.data.data.length === 0) {
        toast.error("No Sub Category Found")
        setgetCat(result.data.data)
      }

    }
    catch (error) {
      console.log(error);
    }
  }

  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }

  return (
    <div className="category">
      <ToastContainer />
      <div class="row">
        <div class="col-md-12">
          <div class="search-box">
            <div class="searchtct">

              <i class="fa fa-search" aria-hidden="true"></i>
              <input type="text" name="text" placeholder="Search..." ref={textInput} />
              <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>
              <button type='button' className='searchBtn'><Link to="/addsubcategory" >Add</Link></button>

            </div>

          </div>
          {getcat ?
            <div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>SL NO</th>
                      <th>Categoty Image</th>
                      <th>Categoty Name</th>
                      <th>Sub Categoty Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getcat?.map((item, i) => {
                        return <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <div className='catIcon'>
                              <img src={item?.image_path} alt={item?.image} />
                            </div>
                          </td>
                          <td>{item?.category ? item?.category.name : "--"}</td>
                          <td>{item?.name ? item?.name : "--"}</td>
                          <td>
                            <div class="action">
                              <Link title='View Category' to={`/subcategoryview/${item?.id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                              <Link title='Edit Category' onClick={() => { editItem(item) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                              <Link title='Delete Category' onClick={() => { deleteBtn(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>

                            </div>
                          </td>
                        </tr>
                      })
                    }

                  </tbody>
                </table>
              </div>
              <div class="tablepgntn">
                <p>Show <select value={getLimit} onChange={(val) => { setLimit(val.target.value); setoffsetValue(0) }}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                </select> Entries</p>
                <ul>
                  {/* <li>
       <Link><i class="fa fa-chevron-left" aria-hidden="true"></i></Link>
     
   </li> */}
                  <li >
                    <Link onClick={() => { setoffsetValue(0) }}>01</Link>

                  </li>
                  <li>
                    <Link onClick={() => { setoffsetValue(10) }}>02</Link>

                  </li>
                  <li>
                    <Link onClick={() => { setoffsetValue(20) }}>03</Link>

                  </li>
                  <li>
                    <Link onClick={() => { setoffsetValue(30) }}>04</Link>

                  </li>
                  {/* <li>
       <Link><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>

   </li> */}
                </ul>
              </div>
            </div> :
            <Loding />
          }




        </div>
      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={deletconfirm}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubCategory;