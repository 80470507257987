import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { toast } from 'react-toastify';
import httpClient from '../HTTPService';


const ForgetPassword = () => {
 
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
  return (
    <div className="logwapper">
      <div className="logwapper-body">
        {/* <div className="logwapper-left">
          <a href="login.html"><img src={logo} alt="logo" /></a>
        </div> */}
        <div className="logwapper-right">
          <form>
            <h1>Welcome Back</h1>
            <div className="form-group">
              <i className="fa fa-user" aria-hidden="true"></i>
              <input 
                type="text"
                name="email"
                placeholder="Email Id"
                onChange={(val) => setEmail(val.target.value)}
                value={email}
                required
              />
            </div>
            <div className="form-group">
              <i className="fa fa-lock" aria-hidden="true"></i>
              <input 
                type="password"
                name="password"
                placeholder="Password"
                onChange={(val) => setPassword(val.target.value)}
                value={password}
                required
              />
            </div>
            <div className="flx">
              <div className="checkboxs">
                <input type="checkbox" id="css" />
                <label htmlFor="css">Remember Me</label>
              </div>
              <a href="forgotpassword.html">Forgot Password ?</a>
            </div>
            <button type="submit" className="themeBtn" disabled={loading}>
              {loading ? 'LOADING...' : 'LOGIN'}
            </button>
            {errorMsg && <p className='errormsg'>{errorMsg}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
