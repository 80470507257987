import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { useState } from "react";
import './Category.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
const CategoryDetails = () =>{
 const navigate = useNavigate();
    const [getcatDetail, setgetcatDetails] = useState();
    const params = useParams();
    const {id} = params
    console.log(id);
    // edit
   const  editdata = (e)=>{
        navigate('/addCategory');
        const edit =  JSON.stringify(e)
        JSON.stringify(localStorage.setItem('editData',edit))
    }

    // get cat details
    useEffect(()=>{
        getcatDetails();
    },[])
        const getcatDetails = async ()=>{
            try{
            const result = await httpClient.post("/admin/categories/view",{id:id},{
                headers:{
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`   
                }
            });
            if(result.data.status){
                // toast.success(result.data.message)
                setgetcatDetails(result.data.data)
            }
            }catch(error){
                // toast.error(error.message)
            }
        }

    return(
         <div className="CategoryDetails">
                   <ToastContainer />
                        <div className="card">
                        <div class="row">
                        <div className="col-md-12">
                            <div className="catImagettl">
                                <div className="actioncat">
                                <Link to="" className="editcat"><i class="fa fa-pencil" aria-hidden="true" onClick={()=>{editdata(getcatDetail)}}></i></Link>
                                <Link to="/category" className="backcat" ><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                                </div>
                        
                                <div className="catimg">
                                    <img src={getcatDetail?.image_path}/>
                                </div>
                                <h2>{getcatDetail?.name}</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="catDetails">
                              <h3>Drescption</h3>
                              <p>{getcatDetail?.description}</p>
                              <p><label>Created Date :</label>{getcatDetail?.created_at}</p>
                              <p><label>Updated Date :</label>{getcatDetail?.updated_at}</p>
                              <p><label>Status :</label><span className={getcatDetail?.active? "active": "inactive"}>{getcatDetail?.active? "Active": "Inactive"}</span></p>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
      
    )
}
export default CategoryDetails;