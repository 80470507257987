import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { useState } from "react";
import './Subscription.css';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
const SubscriptionDetails = () => {
    const navigate = useNavigate();
    const [getsubDetail, setgetSubDetails] = useState();
    const params = useParams();
    const { id } = params

    // Edit Subcription
    const editdata = (e) => {
        navigate('/howitsworkaddedit');
        const editWorks = JSON.stringify(e)
        JSON.stringify(localStorage.setItem('editSubData', editWorks))
    }

    // Get Subcription Details
    useEffect(() => {
        getSubcriptionDetails();
    }, [])
    const getSubcriptionDetails = async () => {
        try {
            const result = await httpClient.post("/admin/subscriptions/view", { id: id }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (result.data.status) {

                setgetSubDetails(result.data.data);
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    return (
        <div className="SubscriptionDetails">

            <div className="card">
                <div class="row">
                    <div className="col-md-12">
                        <div className="catImagettl">
                            <div className="actioncat">
                                <Link to="" className="editcat"><i class="fa fa-pencil" aria-hidden="true" onClick={() => { editdata(getsubDetail) }}></i></Link>
                                <Link to="/subscription" className="backcat" ><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                            </div>


                            <h2>{getsubDetail?.name}</h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                    <div className="catDetails">
                        {
                            getsubDetail?.subscriptions?.map((item) =>{
                                return(
                                    <div>
                                    <h3>Drescption</h3>
                                    <p>{getsubDetail?.description}</p>
                                    <p><label>Plan Name:</label> {getsubDetail?.name}</p>

                                    <p><label>Price:</label><span> ₹{item?.mrp}</span> <span className="linetrue">₹{getsubDetail?.mrp}</span></p>
                                    <p><label>Discounted Price:</label><span> ₹{item?.price}</span> <span className="linetrue">₹{getsubDetail?.mrp}</span></p>
                                    <p><label>Discounted Percentage (%):</label><span>{item?.discount}</span> </p>

                                    <p><label>Validity:</label> {item?.days} Days</p>
                                    <p><label>Status :</label> <span className={getsubDetail?.active ? "active" : "inactive"}>{getsubDetail?.active ? "Active" : "Inactive"}</span></p>
                               </div>
                                )

                           
                        {/* </div> */}
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SubscriptionDetails;