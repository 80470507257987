import React, { useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import Loding from '../../Component/Loding/Loding';
import httpClient from '../../HTTPService';
import { useState } from 'react';
import noImage from "../../assets/noimage.jpg"
import './Category.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const Category = () => {
  const [offsetValue, setoffsetValue] = useState(0);
  const navigate = useNavigate()
  const [deleteyes, setdeleteyes] = useState(null)
  const [getcat, setgetCat] = useState()
  const [getLimit, setLimit] = useState(10);
  let textInput = React.createRef();
  useEffect(() => {
    getCat();
  }, [offsetValue, getLimit]);
  //
  const editItem = (e) => {
    console.log(e);
    // const editdata = JSON.stringify(e)
    // const {id} = editdata
    // console.log(editdata)
    navigate(`/addCategory?id=${e.id}`);
    // navigate('/addCategory', );
    //  localStorage.setItem('editData',editdata)
  }
  // Search Category
  const searchtxt = () => {
    getCat(textInput.current.value);
  }
  // Delete Category


  const deleteBtn = (id) => {
    document.getElementById('modalpop').classList.add('open')
    setdeleteyes(id)

  }
  const deletconfirm = async () => {
    try {
      const result = await httpClient.post('/admin/categories/delete', { id: deleteyes }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.status == true) {
        toast.success('Category Detele Successfully!')
        getCat();
        closeBtn();
      }
      if (result.data.status == false) {
        toast.error(result.data.message)
        closeBtn();
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
  // get Category
  const getCat = async (search_name) => {
    try {
      const result = await httpClient.post('/admin/categories-list', { search_name, offset: offsetValue, limit: getLimit }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      console.log(result.data.data);
      if (result.data.status && result.data.data.length !== 0) {
        // toast.success("Category featch Successfully!")
        setgetCat(result.data.data);

      }
      if (result.data.data.length === 0) {
        toast.error("No Category Found")
        setgetCat(result.data.data)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }

  return (
    <div className="category">
      <ToastContainer />
      <div className="row">
        <div className="col-md-12">
          
          <div className="search-box">
            <div className="searchtct">

              <i className="fa fa-search" aria-hidden="true"></i>
              <input type="text" name="text" placeholder="Search..." ref={textInput} />
              <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>
              <button type='button' className='searchBtn'><Link to="/addCategory" >Add</Link></button>

            </div>

          </div>
          {getcat ?
            <div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>SL NO</th>
                      <th>Categoty Icon</th>
                      <th>Categoty Image</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getcat?.map((item, i) => {
                        return <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <div className='catIcon'>
                              <img src={item?.icon_path ? item?.icon_path : noImage} alt={item?.icon_path} />
                            </div>
                          </td>
                          <td>
                            <div className='catIcon'>
                              <img src={item?.image_path} alt={item?.image} />
                            </div>
                          </td>
                          <td>{item?.name ? item?.name : "--"}</td>
                          <td>
                            <div className="action">
                              <Link title='View Category' to={`/categoryview/${item?.id}`}><i className="fa fa-eye" aria-hidden="true"></i></Link>
                              <Link title='Edit Category' onClick={() => { editItem(item) }}><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                              <Link title='Delete Category' onClick={() => { deleteBtn(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>

                            </div>
                          </td>
                        </tr>
                      })
                    }

                  </tbody>
                </table>
              </div>
              <div className="tablepgntn">
                <p>Show <select value={getLimit} onChange={(val) => { setLimit(val.target.value); setoffsetValue(0) }}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                </select> Entries</p>
                <ul>
                  {/* <li>
       <Link><i className="fa fa-chevron-left" aria-hidden="true"></i></Link>
     
   </li> */}
                  <li >
                    <Link onClick={() => { setoffsetValue(0) }}>01</Link>

                  </li>
                  <li>
                    <Link onClick={() => { setoffsetValue(10) }}>02</Link>

                  </li>
                  <li>
                    <Link onClick={() => { setoffsetValue(20) }}>03</Link>

                  </li>
                  <li>
                    <Link onClick={() => { setoffsetValue(30) }}>04</Link>

                  </li>
                  {/* <li>
       <Link><i className="fa fa-chevron-right" aria-hidden="true"></i></Link>

   </li> */}
                </ul>
              </div>
            </div> :
            <Loding />
          }




        </div>
      </div>
      <div className="modalpop" id="modalpop">
        <div className="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div className="popBtn">
            <button type="button" className="secussBtn" onClick={deletconfirm}>
              Yes
            </button>
            <button type="button" className="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Category;