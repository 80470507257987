import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import "./FormManagement.css"
import { useEffect } from 'react';
import { useState } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import { ToastContainer, toast } from 'react-toastify';
import httpClient from '../../HTTPService';
const FormListing = () => {
    const [getForm, setGetForm] = useState();
    useEffect(() => {
        getFormList();
    }, []);
    const getFormList = async () => {
        try {
            const result = await httpClient.post("/admin/form-list", {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            console.log(result.data.data);
            if (result.data.status == true) {
                // toast.success(result.data.message)
                setGetForm(result.data.data)
            }
            if (result.data.status == false) {
                // toast.error(result.data.message)

            }

        }

        catch (error) {
            // toast.error(error.message)
        }
    }
    return (
        <div className="FormList">

            <ToastContainer />

            <Accordion>
                {getForm?.map((item, i) => {
                    return <AccordionItem key={i}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {`${item.category} / ${item.sub_category}`}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className='viewForm'>

                                <div className='row'>
                                    {JSON.parse(item?.form_data).map((frmItem, index) => {
                                        if (frmItem.type == "text") {
                                            return <div className='col-md-4' key={index}>
                                                <div className='formGroup'>
                                                    <label>{frmItem.label}</label>
                                                    <input type={frmItem.type} name={frmItem.name} placeholder={frmItem.place_holder}></input>
                                                </div>
                                            </div>
                                        }
                                        if (frmItem.type == "select") {
                                            return <div className='col-md-4' key={index}>
                                                <div className='formGroup'>
                                                    <label>{frmItem.label}</label>
                                                    <select>
                                                        <option></option>
                                                    </select>

                                                </div>
                                            </div>
                                        }
                                        if (frmItem.type == "date") {
                                            return <div className='col-md-4' key={index}>
                                                <div className='formGroup'>
                                                    <label>{frmItem.label}</label>
                                                    <input type={frmItem.type} name={frmItem.name} placeholder={frmItem.place_holder}></input>

                                                </div>
                                            </div>
                                        }
                                        if (frmItem.type == "time") {
                                            return <div className='col-md-4' key={index}>
                                                <div className='formGroup'>
                                                    <label>{frmItem.label}</label>
                                                    <input type={frmItem.type} name={frmItem.name} placeholder={frmItem.place_holder}></input>

                                                </div>
                                            </div>
                                        }
                                        if (frmItem.type == "textarea") {
                                            return <div className='col-md-4' key={index}>
                                                <div className='formGroup'>
                                                    <label>{frmItem.label}</label>
                                                    <textarea placeholder={frmItem.place_holder} name={frmItem.name}></textarea>


                                                </div>
                                            </div>
                                        }
                                        if (frmItem.type == "file") {
                                            return <div className='col-md-4' key={index}>
                                                <div className='formGroup'>
                                                    <label>{frmItem.label}</label>
                                                    <input type={frmItem.type} name={frmItem.name}></input>


                                                </div>
                                            </div>
                                        }
                                    })
                                    }
                                </div>

                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                })

                }

            </Accordion>
        </div>
    )
}
export default FormListing;