
import httpClient from '../../HTTPService';
import './Subscription.css';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Loding from '../../Component/Loding/Loding';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const Subscription = () => {
  const [offsetValue, setoffsetValue] = useState(0);
  const navigate = useNavigate();
  const [deleteyes, setdeleteyes] = useState(null)
  const [getSubscription, setSubscription] = useState();
  let textInput = React.createRef();
  const [getLimit, setLimit] = useState(10);
  useEffect(() => {
    getSub();
  }, [offsetValue, getLimit])
  // Edit Subcription
  const editItem = (e) => {
    console.log(e);
    // const editSub = JSON.stringify(e)
    navigate(`/addeditsubscription?id=${e?.id}`,);
    //  localStorage.setItem('editSubData',editSub)
  }
  // Delete subscription
  const deletconfirm = async () => {
    try {
      await httpClient.post('/admin/subscriptions/delete', { id: deleteyes }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      toast.success('Subscription Detele Successfully!')
      getSub();
      closeBtn();

    }
    catch (error) {
      toast.error(error.message)
    }

  }
  // search Subcription
  const searchtxt = () => {
    getSub(textInput.current.value);
  }
  // get Subcription

  const getSub = async (search_name) => {
    try {
      const result = await httpClient.post("/admin/subscriptions-list", { search_name, offset: offsetValue, limit: getLimit }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (result.data.status && result.data.data.length !== 0) {
        // toast.success("Subscription featch Successfully!")
        setSubscription(result.data.data);
      }
      if (result.data.data.length === 0) {
        toast.error("No Subscription Found")
        setSubscription(result.data.data);
      }
    } catch (error) {
      toast.error(error.message)
    }


  }

  const deleteBtn = (id) => {
    document.getElementById('modalpop').classList.add('open')
    setdeleteyes(id)
  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }
  return (
    <div className='subscription'>
      <div class="row">
        <div class="col-md-12">
          <div class="search-box">
            <div class="searchtct">

              <i class="fa fa-search" aria-hidden="true"></i>
              <input type="text" name="text" placeholder="Search..." ref={textInput} />
              <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>
              <button type='button' className='searchBtn'><Link to="/addeditsubscription" >Add</Link></button>

            </div>

          </div>
          {getSubscription ? <div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>SL NO</th>
                    <th>Name</th>
                    {/* <th>Validity</th>
                <th >Description</th> */}
                    {/* <th>Discount</th>
                <th>Price</th> */}
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {getSubscription?.map((item, i) => {
                    return <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item.name ? item.name : "--"}</td>
                      {/* <td>{item.days? `item.days days` :"--"}</td>
                    <td ><p>{item.description? item.description: "--"}</p></td> */}
                      {/* <td>{item.discount? item.discount: "--"}</td>
                    <td><span>₹{item.price}</span><small className='cutPrice'>₹{item.mrp}</small></td> */}
                      <td>{  `${moment(item?.subscriptions[0].created_at).format('DD-MM-YYYY')}` }</td>


                      <td>
                        <span className={item?.subscriptions[0].active == 1? "active" : "inactive"}>{item?.subscriptions[0].active == 1 ? "Active" : "Inactive"}</span>
                      </td>
                      <td>
                        <div class="action">
                          <Link to={`/subscriptionview/${item?.id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                          <Link onClick={() => { editItem(item) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                          <Link onClick={() => { deleteBtn(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>

                        </div>
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </div>
            <div class="tablepgntn">
              <p>Show <select value={getLimit} onChange={(val) => { setLimit(val.target.value); setoffsetValue(0) }}>
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
                <option>50</option>
              </select> Entries</p>
              <ul>
                {/* <li>
                <Link><i class="fa fa-chevron-left" aria-hidden="true"></i></Link>
              
            </li> */}
                <li >
                  <Link onClick={() => { setoffsetValue(0) }}>01</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(10) }}>02</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(20) }}>03</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(30) }}>04</Link>

                </li>
                {/* <li>
                <Link><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>
    
            </li> */}
              </ul>
            </div>
          </div> :
            <Loding />

          }

        </div>
      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={deletconfirm}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}
export default Subscription;