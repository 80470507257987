import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';
import noImage from "../../assets/noimage.jpg";
import { useEffect } from "react";
import './HowItsWork.css';
const HowItsWorkAddEdit = ()=>{
const navigate = useNavigate();
const [categotyName, setCategotyName] =useState("");
const [catStatus, setStatus] =useState("");
const [catImage, setcatImage] =useState("");
const [imagePrvw, setimagePrvw] =useState();
const [itemId, setitemId] =useState('');
const [editMode, seteditMode]= useState(false)
   // Edit How Work
   useEffect(()=>{
    if(localStorage.getItem('editWorkData')){
       const  currentData = JSON.parse(localStorage.getItem('editWorkData'));

       console.log(currentData);
        setCategotyName(currentData.title)
        setimagePrvw(currentData.video_path)
        setitemId(currentData.id)
        setStatus(currentData.active)
        seteditMode(true)
     }

   },[])
   // upload Image
   const  uploadcat = (e)=>{
    setcatImage(e.target.files)
    console.log(e.target.files[0]);
    setimagePrvw(URL.createObjectURL(e.target.files[0]))
    }
    const addCatform = async (event)=>{
        event.preventDefault();
        if(localStorage.getItem('editWorkData')){
            const formData = new FormData();
            formData.append('id', itemId);
            formData.append('title', categotyName);
            formData.append('active', catStatus);
            formData.append('image', catImage[0]); 
               try{
                     const result = await httpClient.post("/admin/works/update",formData,{
                        headers:{
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem('token')}`  
                        }
                     })
                     if(result.data.status){
                        localStorage.removeItem('editWorkData')
                        setimagePrvw(null);
                        setCategotyName("")
                        document.getElementById('addForm').reset();
                        toast.success(result.data.message);
                          setTimeout(()=>{
                            navigate('/howitswork')
                          },1000)
                     
                     }
                    
                   
                     if(result.data.status == false){
                        toast.error(result.data.message)
                     }
                   
                    
               }
               catch(error){
                toast.error(error.message)
               }
        }
        else{
            const formData = new FormData();
            formData.append('title', categotyName);
            formData.append('active', catStatus);
            formData.append('video', catImage[0]); 
           try{
                const result = await httpClient.post("/admin/works/create",formData,{
                    headers:{
                        "Content-Type": "multipart/form-data",
                        "Accept": "application/json", 
                        'Authorization': `Bearer ${localStorage.getItem('token')}`  
                    }
                   
                });
                setimagePrvw(null);
                setCategotyName("")
                document.getElementById('addForm').reset();
                toast.success(result.data.message)
                setTimeout(()=>{
                    navigate('/howitswork')
                  },1000)
           }
           catch(error){
            toast.error(error.message)
    
           }
        }

       
   
    } 
    return(
        <div className="howitsWorks">
              <ToastContainer />
  <div className="card">
  <form onSubmit={addCatform} id="addForm">
                <div className="row">
                    <div className="col-md-4">
                        <div className="formGroup flxfrm">
                              <div className="catIcon">
                                {imagePrvw?
                                 <video src={imagePrvw} controls> </video>:<img src={noImage}/>
                                }
                             
                          
                        
                              </div>
                              
                              <span className="uploadImage" onChange={(e)=>{uploadcat(e)}}><input type="file" name="image"/><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="formGroup">
                            <label>Category Name</label>
                            <input type="text" name="categotyName" placeholder="Name"  onChange={(val) => setCategotyName(val.target.value)} value={categotyName} required/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="formGroup">
                           <label>Status</label>
                            <select value={catStatus} onChange={(val) => setStatus(val.target.value)} >
                                <option>Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                                </select> 
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="btngrp">
                        <button type="button" className="themeBtn cancelBtn" onClick={()=>{navigate('/howitswork'); localStorage.removeItem('editWorkData')}}>Cancel</button>
                        <button type="submit" className="themeBtn">{editMode? "Update":"Submit"}</button>
                        </div>
                
                    </div>
                </div>
             
                </form>
            </div>
        </div>
    )

}
export default HowItsWorkAddEdit;