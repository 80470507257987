import httpClient from '../../HTTPService';
import './User.css';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loding from '../../Component/Loding/Loding';
import moment from 'moment';
const User = () => {
  const [offsetValue, setoffsetValue] = useState(0);
  const [userList, setUserList] = useState();
  const [getLimit, setLimit] = useState(10);
  const [deleteUser, setDeleteUser] = useState();
  
  let textInput = React.createRef();

  useEffect(() => {
    getUser()
  }, [offsetValue, getLimit])
  // change Status
  const changeStatus = async (id) => {
    try {
      const result = await httpClient.post('/admin/users/change-status', { id: id }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log(result.data);
      toast.success("Status Change Successfully!")
      getUser();
    } catch (error) {
      toast.error(error.message)

    }
  }
  const searchtxt = () => {
    getUser(textInput.current.value);
  }
  const getUser = async (search_name) => {
    try {
      const result = await httpClient.post('/admin/users-list', { search_name, offset: offsetValue, limit: getLimit }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.status && result.data.data.length !== 0) {
        //  toast.success("User featch Successfully!")
        setUserList(result.data.data)
        console.log(result.data.data);
      }
      if (result.data.data.length === 0) {
        // toast.error("No user Found")
        setUserList(result.data.data)
      }
    } catch (error) {
      // toast.error(error.message)
    }


  }

  const deleteBtn = (id) => {

    setDeleteUser(id)
    document.getElementById('modalpop').classList.add('open')
  }
  const closeBtn = () => {
    document.getElementById('modalpop').classList.remove('open')
  }
  const yesDelete = async () => {
    try {
      const result = await httpClient.post('/admin/users-delete', { id: deleteUser }, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log(result.data);

      if (result.data.status == true) {
        toast.success("User Delete Successfully!")
        getUser();
        closeBtn();
      }
      if (result.data.status == false) {
        toast.error(result.data.message);
        closeBtn();
      }
      // if(result.data.data.length === 0){
      //  toast.error("Something went worng")
      //  getUser();
      //  closeBtn();
      // }
    } catch (error) {
      toast.error(error.message)
    }

  }
  return (
    <div className='usersec'>
      <ToastContainer />
      <div class="row">
        <div class="col-md-12">
          <div class="search-box">
            <div class="searchtct">

              <i class="fa fa-search" aria-hidden="true"></i>
              <input type="text" name="text" placeholder="Search..." ref={textInput} />
              <button type='button' className='searchBtn' onClick={searchtxt}>Search</button>


            </div>

          </div>
          {userList ? <div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>SL NO</th>
                    <th>Member Code</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>Email</th>
                    <th>Purchase Count</th>
                    <th>Referred By</th>
                    <th>Registered On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {userList?.map((item, i) => {
                    return <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.member_code ? item?.member_code : "--"}</td>
                      <td>{item?.name ? item?.name : "--"}</td>
                      <td>{item?.phone ? item?.phone : "--"}</td>
                      <td>{item?.email ? item?.email : "--"}</td>
                      <td>{item?.purchase_count ? item?.purchase_count : "--"}</td>
                      <td>{item?.referred_by ? item?.referred_by : "--"}</td>
                      <td>{item?.joing_date ? `${moment(item?.joing_date).format('DD-MM-YYYY')} ${moment(item?.joing_date).format('LT')}` : "--"}</td>
                      <td>
                        <span onClick={() => { changeStatus(item?.id) }} className={item?.active == 1 ? "active curso" : "inactive  curso"}>{item?.active == 1 ? "Active" : "Inactive"}</span>
                      </td>
                      <td>
                        <div class="action">
                          <Link><i class="fa fa-user" aria-hidden="true"></i></Link>
                          <Link><i class="fa fa-lock" aria-hidden="true"></i></Link>
                          <Link to={`/useredit/${item?.id}`} onClick={() => { localStorage.setItem('editUsers', JSON.stringify(item)) }}><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                          <Link onClick={() => { deleteBtn(item?.id) }}><i class="fa fa-trash" aria-hidden="true"></i></Link>

                        </div>
                      </td>
                    </tr>
                  })
                  }
                </tbody>
              </table>
            </div>
            <div class="tablepgntn">
              <p>Show <select value={getLimit} onChange={(val) => { setLimit(val.target.value); setoffsetValue(0) }}>
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
                <option>50</option>
              </select> Entries</p>
              <ul>
                {/* <li>
                <Link><i class="fa fa-chevron-left" aria-hidden="true"></i></Link>
              
            </li> */}
                <li class="active">
                  <Link onClick={() => { setoffsetValue(0) }}>01</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(10) }}>02</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(20) }}>03</Link>

                </li>
                <li>
                  <Link onClick={() => { setoffsetValue(30) }}>04</Link>

                </li>
                {/* <li>
                <Link><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>
    
            </li> */}
              </ul>
            </div>
          </div> :
            <Loding />

          }

        </div>
      </div>
      <div class="modalpop" id="modalpop">
        <div class="modalsbody">
          <h3>Do You Want to Delete ?</h3>
          <div class="popBtn">
            <button type="button" class="secussBtn" onClick={yesDelete}>
              Yes
            </button>
            <button type="button" class="cancelBtn" onClick={closeBtn}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}
export default User;