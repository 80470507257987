import {Outlet} from "react-router-dom";
import './Layout.css';
 import Header from "./Header/Header";
 import Menu from "./Menu/Menu";
const Layout = () =>{
    return(
     <div className="layout" id="mainWapper">
        <div className="menu">
          <Menu/>
        </div>
        <div className="wapper">
        <div className="header">
        <Header/>
        </div>
        <div className="wapperbody">
   
            <Outlet/>
        </div>
        </div>
     </div>
    )
 }
 export default Layout;