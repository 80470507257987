import React, { useState } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import './OptionManagement.css';
import { useEffect } from "react";
import httpClient from "../../HTTPService";
import { ToastContainer, toast } from 'react-toastify';
const AddEditOption = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [optionName, setOptionName] = useState();
    const [optionValue, setOptionValue] = useState();
    const [editMode, setEditMode] = useState();
    const [editid, setEditID] = useState();
    const [optionData, setOptiondata] = useState([]);
    // let textInput = React.createRef()

useEffect(() =>{
 console.log(location)
},[])
    useEffect(() => {
        console.log(optionValue);
        let currentOptions = JSON.parse(localStorage.getItem('editOptions'))
        if (currentOptions) {
            setOptionName(currentOptions?.name)
            setOptiondata(currentOptions?.options)
            setEditID(currentOptions?.id);

        }

        if (localStorage.getItem('editOptions')) {
            setEditMode(true)
        }
    }, [])

    const updateoption = (index1, optionValue) => {

        let updata = optionData.map((ele, index) => {
            // console.log(ele);
            if (index === index1) {
                return {...ele, name: optionValue}
            }
            else
                return {...ele,name:ele.name}
        });
        setOptiondata(updata);
        setOptionValue('');
    };

    const addoption = () => {
        setOptiondata(prevOptionData => {
            if (prevOptionData.length > 0) {
                return [...prevOptionData, { name: optionValue }];
            } else {
                return [{ name: optionValue }];
            }
        });
        setOptionValue('');
    };
    const addOptionForm = async () => {
        if (editMode) {
        

            try {
                const result = await httpClient.post("/admin/option-masters/update", { id: editid, name: optionName, option_data: optionData }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
                if (result.data.status == true) {
                    setTimeout(() => {
                        navigate('/optionmanagement')
                    })
                    toast.success("Option Update Successfully")
                }

            }
            catch (error) {
                toast.error(error.message)
            }
        }
     else{
        try {
            const result = await httpClient.post("/admin/option-masters/create", { name: optionName, option_data: optionData }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            if (result.data.status == true) {
                setTimeout(() => {
                    navigate('/optionmanagement')
                })
                toast.success("Option Create Successfully")
            }
            if (result.data.status == false) {
                toast.error(result.data.message)
            }
        }
        catch (error) {
            toast.error(error.message)
        }
     }
       
    }
    return (
        <div className="AddEditOption">
            <ToastContainer />
            <div className="card">

                <div className="row">
                    <div className="col-md-4">
                        <div className="formGroup">
                            <label>Option Name</label>
                            <input type="text" name="optionName" placeholder="Name" onChange={(val) => setOptionName(val.target.value)} value={optionName} required />
                        </div>
                    </div>
                </div>
                <div className="scrollBar">
                    {/* {optionData? optionData?.map((item, index)=>{
                         return  <div className="row" key={index}>
                         <div className="col-md-4">
                            <div className="formGroup ">
                            <input type="text" name="optionValue" placeholder="Option Value" value={item.name}/>
                            </div>
                        </div>
                        </div>
                     
                       
                    })} */}
                    {editMode ?
                        optionData?.map((item, index) => {
                            return <div className="row" key={index}>
                                <div className="col-md-4">
                                    <div className="formGroup ">
                                        <input type="text" name="optionValue" placeholder="Option Value" defaultValue={item.name} onChange={(val) => { updateoption(index,val.target.value) }} />
                                    </div>
                                </div>
                            </div>

                        }) : optionData?.map((item, index) => {
                            return <div className="row" key={index}>
                                <div className="col-md-4">
                                    <div className="formGroup ">
                                        <input type="text" name="optionValue" placeholder="Option Value" value={item.name} />
                                    </div>
                                </div>
                            </div>


                        })
                    }
                </div>

                
                    <div className="row">
                        <div className="col-md-4">

                            <div className="formGroup flxFrm">
                                <input type="text" name="optionValue" value={optionValue} placeholder="Option Value" onChange={(val) => { setOptionValue(val.target.value) }} />
                                <button type="button" className="addBtn" onClick={addoption} >Add</button>
                            </div>


                        </div>
                    </div> 
            



                <div className="row">
                    <div className="col-md-4">
                        <div className="btngrp">
                            <button type="button" className="themeBtn cancelBtn" onClick={() => { navigate('/optionmanagement'); localStorage.removeItem('editOptions') }}>Cancel</button>
                            <button type="submit" className="themeBtn" onClick={addOptionForm}>{editMode ? "Update" : "Submit"}</button>
                        </div>
                    </div>
                </div>



            </div>
        </div>

    )
}
export default AddEditOption;