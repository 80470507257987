import './Cms.css';
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react';
import httpClient from '../../HTTPService';
const Cms = ()=>{
    const [videoUrl, setVideoUrl] = useState();
    const uploadVideo = async () =>{
        try{
            const result =  await httpClient.post('/admin/home-cms/update',{video_link:videoUrl},{
                headers:{
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem('token')}`  
                }
              });
            
               if(result.data.status == true){
                toast.success('Video Update Successfully!');
                setVideoUrl('');
               }
               if(result.data.status == false){
                toast.error(result.data.message)
               }
            }
            catch(error){
              toast.error(error.message)    
            }
    }
    return(
        <div className="cms">
            <ToastContainer />
          <div className="card">
         <div className='row'>
           <div className='col-md-4'>
             <div className='formGroup'>
                <label>Upload Video URL</label>
                <input type='text' value={videoUrl} placeholder='Video URL' onChange={(val)=>setVideoUrl(val.target.value)}/>
             </div>
            </div>
            <div className='col-md-4'>
              <label>&nbsp;</label><br/>
                <button type='button' className='searchBtn' onClick={uploadVideo}>Upload</button>
            </div>
         </div>
         </div>
        </div>
    )
}
export default Cms;